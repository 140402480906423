























































































































import {
  get_device,
  get_setting_user,
  patch_capture,
  post_capture,
} from "@/api";
import {
  captureDetailType,
  deviceType,
  settingUserDetailShiftType,
  settingUserType,
} from "@/api/api.types";
import { VuetifyForm } from "@/shared/types";
import { required } from "@/shared/validation";
import { Notif } from "@/store";
import moment from "moment";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

const DatePicker = () => import("@/components/DatePicker.vue");
const TimePicker = () => import("@/components/TimePicker.vue");

@Component({ name: "form-capture", components: { DatePicker, TimePicker } })
export default class FormCapture extends Vue {
  /* Constants */

  @Prop({ required: true }) readonly value!:
    | boolean
    | captureDetailType["data"];

  required = required;

  /* Variables */

  uuid = null as string | null;

  disableOut = false; // disable out if already out (only for edit), bcs why not!!!!

  out = false; // Toggle for show out form

  formData = {
    userProfile: null as unknown as string,
    summaryDate: null as unknown as string,
    shift: null as unknown as string,
    inTime: null as unknown as string,
    outTime: null as null | string,
    deviceIn: null as unknown as string,
    deviceOut: null as null | string,
  };

  userList = [] as settingUserType["data"]["_embedded"]["profile"];
  userLoad = false;

  shiftList = [] as settingUserDetailShiftType[];
  shiftLoad = false;

  deviceList = [] as deviceType["data"]["_embedded"]["device"];
  deviceLoad = false;

  /* Computed */
  /* Methods */

  @Emit("submit")
  async submit(): Promise<void> {
    try {
      if (!(this.$refs.formData as VuetifyForm).validate()) return;
      const form = { ...this.formData }; // Put it to form so this.formData not manipulated

      // In - Out Time Manipulation
      form.inTime = `${form.summaryDate} ${form.inTime}`;
      if (form.outTime) {
        form.outTime = `${form.summaryDate} ${form.outTime}`;
      }

      // If out value is false, formData.[*out*] will not be send
      if (!this.out) {
        this.formData.outTime = undefined as any;
        this.formData.deviceOut = undefined as any;
      }

      // Post & Patch
      if (this.value == true) {
        await post_capture(form);
      } else {
        form.userProfile = undefined as any;
        form.summaryDate = undefined as any;
        if (this.uuid) await patch_capture(this.uuid, form);
      }
      Notif.notif_success("Capture Log Added");
      this.close();
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  @Emit("input")
  close(): boolean {
    (this.$refs.formData as VuetifyForm).reset();
    this.uuid = null;
    this.disableOut = false;
    return false;
  }

  async fetchUser(): Promise<void> {
    this.userLoad = true;
    try {
      const resp = await get_setting_user({ limit: -1 });
      this.userList = resp.data._embedded.profile;
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.userLoad = false;
    }
  }

  async fetchDevice(): Promise<void> {
    this.deviceLoad = true;
    try {
      const resp = await get_device({ limit: -1 });
      this.deviceList = resp.data._embedded.device;
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.deviceLoad = false;
    }
  }

  timeFormat(time: string): string {
    return moment(time, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss");
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchUser();
  }

  @Watch("value")
  onValueChange(now: boolean | captureDetailType["data"]): void {
    if (now) {
      this.fetchDevice();

      // Take Form Data
      if (typeof now != "boolean") {
        this.uuid = now.uuid;
        this.formData.userProfile = now.userProfile.uuid;
        this.formData.summaryDate = now.summaryDate;
        this.formData.shift = now.shift && now.shift.uuid;
        this.formData.inTime = this.timeFormat(now.inTime);
        this.formData.outTime = now.outTime && this.timeFormat(now.outTime);
        this.formData.deviceIn = now.deviceIn.uuid;
        this.formData.deviceOut = now.deviceOut ? now.deviceOut.uuid : null;
        if (now.outTime) {
          this.out = true;
          this.disableOut = true;
        }
      }
    }
  }

  @Watch("formData.userProfile")
  onUserProfileChange(now: string): void {
    const user = this.userList.find((t) => t.uuid == now);
    if (!user) {
      this.shiftList = [];
    } else {
      const temp = user.userShift;
      if (temp.length) {
        this.shiftList = temp.map(({ createdAt, createdBy, shift }) => {
          return { ...shift, createdAt, createdBy };
        });
      }
      // this.shiftList = user.shifts;
    }
  }
}
